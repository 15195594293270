import { render, staticRenderFns } from "./codelogin.vue?vue&type=template&id=4d6856c3&scoped=true&"
import script from "./codelogin.vue?vue&type=script&lang=js&"
export * from "./codelogin.vue?vue&type=script&lang=js&"
import style0 from "./codelogin.vue?vue&type=style&index=0&id=4d6856c3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d6856c3",
  null
  
)

export default component.exports